<template>
    <div class="flex slot" :class="slotClasses" :style="slotStyles">
        <template v-for="content in layoutSlot.contents" :key="content._uid">
            <Component :is="content.component" :blok="content" />
        </template>
    </div>
</template>
<script setup lang="ts">
import type { ICoreLayoutSlot } from '~ui/types/components/CoreLayout';
import { buildBackground } from '~ui/utils/styleBuilder';
import { EComponentDirections, EHideOnType } from '~ui/types/enums/ComponentStyle';
import { numberToPx } from '~ui/utils/helpers';

const { layoutSlot, hasCustomItemPerRow = false } = defineProps<{
    layoutSlot: ICoreLayoutSlot;
    hasCustomItemPerRow?: boolean;
}>();

const slotStyles = computed<Record<string, string | null>>(() => {
    if (!layoutSlot) return {};

    const {
        background,
        gaps,
        paddingTop,
        paddingRight,
        paddingBottom,
        paddingLeft,
        columnWidth,
        direction,
        flexWrap,
        mobileDirection,
        contentAlignment,
        verticalAlignment,
        mobileContentAlignment,
        mobileOrder,
    } = layoutSlot;

    const justifyContent = (direction === EComponentDirections.ROW ? contentAlignment : verticalAlignment) ?? null;
    const justifyMobileContent =
        mobileDirection === EComponentDirections.ROW ? mobileContentAlignment : (justifyContent ?? null);

    return {
        ...buildBackground(background?.[0]),
        gap: numberToPx(gaps),
        paddingTop: numberToPx(paddingTop),
        paddingRight: numberToPx(paddingRight),
        paddingBottom: numberToPx(paddingBottom),
        paddingLeft: numberToPx(paddingLeft),
        '--slot-column': columnWidth && !hasCustomItemPerRow ? `span ${columnWidth} / span ${columnWidth}` : null,
        '--flex-direction': direction ?? null,
        '--flex-wrap': flexWrap ?? null,
        '--flex-mobile-direction': mobileDirection ?? null,
        '--align-content': (direction === EComponentDirections.COLUMN ? contentAlignment : verticalAlignment) ?? null,
        '--justify-content': justifyContent,
        '--align-mobile-content': mobileDirection === EComponentDirections.COLUMN ? mobileContentAlignment : null,
        '--justify-mobile-content': justifyMobileContent,
        '--mobile-order': mobileOrder ?? null,
    };
});

const slotClasses = computed(() => ({
    'hide-mobile': layoutSlot.hideOn === EHideOnType.Mobile,
    'hide-desktop': layoutSlot.hideOn === EHideOnType.Desktop,
}));
</script>

<style scoped lang="postcss">
.slot {
    @apply gap-4 col-auto md:col-[var(--slot-column)];
    flex-direction: var(--flex-direction);
    align-items: var(--align-content);
    justify-content: var(--justify-content);
    flex-wrap: var(--flex-wrap);

    &.hide-desktop {
        @apply md:hidden;
    }

    &.hide-mobile {
        @apply max-md:hidden;
    }

    @media screen and (max-width: 768px) {
        flex-direction: var(--flex-mobile-direction);
        align-items: var(--align-mobile-content);
        justify-content: var(--justify-mobile-content);
        order: var(--mobile-order);
    }
}
</style>
