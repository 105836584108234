<template>
    <div class="ui-core-layout" :class="layoutClasses" :style="layoutStyles">
        <NuxtImg
            v-if="
                blok.background?.[0]?.hasBackgroundImage &&
                blok.background?.[0]?.backgroundImageFit !== 'contain no-repeat'
            "
            :src="blok.background?.[0]?.backgroundImage?.filename ?? ''"
            :alt="blok.background?.[0]?.backgroundImage?.alt ?? ''"
            provider="storyblok"
            sizes="sm:400px md:800px lg:1024px xl:1920px"
            format="webp"
            class="bg-image"
            :class="{
                'object-cover': blok.background?.[0]?.backgroundImageFit === 'cover',
                'object-contain': blok.background?.[0]?.backgroundImageFit === 'contain',
                'object-center': blok.background?.[0]?.backgroundImagePosition === 'center',
                'object-left': blok.background?.[0]?.backgroundImagePosition === 'left',
                'object-right': blok.background?.[0]?.backgroundImagePosition === 'right',
            }"
            densities="x1 x2"
            quality="75"
            loading="lazy"
        />
        <div
            class="mx-auto content-container"
            :class="{ container: !blok.contentFullWidth }"
            :style="!blok.contentFullWidth && !blok.background?.[0]?.hasBackgroundImage ? background : {}"
        >
            <MobileCarousel v-if="isMobileView && blok.mobileBehavior === EMobileBehaviors.CAROUSEL" :blok="blok" />
            <Column v-else :blok="blok" />
        </div>
    </div>
</template>

<script setup lang="ts">
import type { ComputedRef, CSSProperties } from 'vue';
import { buildBackground } from '~ui/utils/styleBuilder';
import type { ICoreLayout } from '~ui/types/components/CoreLayout';
import { useIsMobileView } from '~ui/composables/isMobileView';
import screens from '#tailwind-config/theme/screens';
import Column from '~ui/components/CoreLayout/Column.vue';
import MobileCarousel from '~ui/components/CoreLayout/MobileCarousel.vue';
import { EHideOnType, EMobileBehaviors } from '~ui/types/enums/ComponentStyle';

const { blok } = defineProps<{ blok: ICoreLayout }>();
const { isMobileView } = useIsMobileView(screens.md);

const background: CSSProperties = buildBackground(blok.background?.[0] ?? {}) as CSSProperties;

const layoutStyles: ComputedRef<CSSProperties> = computed(() => ({
    paddingTop: blok.paddingTop ? `${blok.paddingTop}px` : undefined,
    paddingRight: blok.paddingRight ? `${blok.paddingRight}px` : undefined,
    paddingLeft: blok.paddingLeft ? `${blok.paddingLeft}px` : undefined,
    paddingBottom: blok.paddingBottom ? `${blok.paddingBottom}px` : undefined,
}));

const layoutClasses = computed(() => ({
    divider: !!blok.hasDivider,
    'hide-mobile': blok.hideOn === EHideOnType.Mobile,
    'hide-desktop': blok.hideOn === EHideOnType.Desktop,
}));
</script>

<style scoped lang="postcss">
.container {
    @apply mx-auto px-4;
}

.ui-core-layout {
    @apply relative py-6 max-md:py-4 max-w-full;

    .bg-image {
        @apply absolute top-0 left-0 w-full h-full object-cover object-center;
    }

    .content-container {
        @apply relative;
    }

    &.divider {
        border-top: 1px solid #e5e7eb;
        border-bottom: 1px solid #e5e7eb;
    }

    &.hide-desktop {
        @apply md:hidden;
    }

    &.hide-mobile {
        @apply max-md:hidden;
    }
}
</style>
