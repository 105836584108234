<template>
    <div class="core-layout-mobile-carousel" :class="carouselClasses" :style="carouselContainerStyles">
        <LazyClientOnly>
            <Carousel
                ref="carouselRef"
                :items-to-show="1"
                class="transfer-options-carousel"
                :autoplay="blok.show_navigation ? false : 5000"
                snap-align="start"
                :wrap-around="!blok.show_navigation"
            >
                <Slide v-for="slot in slots" :key="slot._uid">
                    <Slot :layout-slot="slot" />
                </Slide>

                <template #addons="{ slidesCount }">
                    <navigation v-if="blok.show_navigation">
                        <template #prev>
                            <NuxtIcon name="ri:arrow-left-line" />
                        </template>
                        <template #next>
                            <NuxtIcon name="ri:arrow-right-line" />
                        </template>
                    </navigation>
                    <Pagination v-if="blok.show_pagination && slidesCount > 1" />
                </template>
            </Carousel>
        </LazyClientOnly>
    </div>
</template>

<script setup lang="ts">
import 'vue3-carousel/dist/carousel.css';

import type { ICoreLayout, ICoreLayoutSlot } from '~ui/types/components/CoreLayout';
import { EHideOnType } from '~ui/types/enums/ComponentStyle';
import Slot from '~ui/components/CoreLayout/Slot.vue';
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';
import { CarouselBtnStyles } from '~ui/types/enums/CarouselBtnStyles';

const { blok } = defineProps<{ blok: ICoreLayout }>();
const carouselRef: any = ref(null);
const slots = computed(() => blok.slots.filter((slot: ICoreLayoutSlot) => slot.hideOn !== EHideOnType.Mobile));

const carouselClasses = computed(() => ({
    'navigation-square': blok.slideNavigationBtnStyle === CarouselBtnStyles.SQUARE,
    'navigation-circle': blok.slideNavigationBtnStyle === CarouselBtnStyles.CIRCLE,
}));

const carouselContainerStyles = computed(() => {
    return { '--navigation-offset': blok.slideNavigationBtnOffset ? `${blok.slideNavigationBtnOffset}px` : null };
});
</script>

<style scoped lang="postcss">
.core-layout-mobile-carousel {
    @apply px-7;
    &.navigation-circle {
        :deep(.carousel) {
            .carousel__next,
            .carousel__prev {
                @apply rounded-[50%];
            }
        }
    }
    :deep(.carousel) {
        &__slide {
            @apply md:px-1;
            align-items: unset;
        }
        .carousel__prev {
            @apply -left-[20px];
        }
        .carousel__next {
            @apply -right-[20px];
        }
        .carousel__next,
        .carousel__prev {
            @apply text-lg rounded-full transition-all -translate-y-1/2 text-[#0094FF] mx-0;
            @apply bg-secondary w-[42px] h-[42px] border-0 rounded-[inherit] transition duration-300 ease-in-out;

            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
            top: calc(50% + var(--navigation-offset, 0px));

            [data-icon] {
                @apply h-[25px] text-white;
            }
        }
        .carousel__next--disabled,
        .carousel__prev--disabled {
            @apply opacity-0 pointer-events-none;
        }
        .carousel__pagination {
            @apply flex mt-6;
        }
        .carousel__pagination-button {
            &::after {
                @apply w-2 h-2 rounded-full bg-gray-400 opacity-25;
            }
        }
        .carousel__pagination-button--active,
        .carousel__pagination-button:hover {
            &::after {
                @apply bg-[#0094FF] opacity-75;
            }
        }
    }
}
</style>
